import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "winke-winke-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#winke-winke-",
        "aria-label": "winke winke  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Winke Winke! 👋🐸`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`This month we got our patent pending Coqui goodness brewing up hot for you!`}</p>
    <p>{`This month we’re gonna let This Little Light of Ours shine on our community,
in particular Thorsten Mueller who has been doing amazing work “fighting the
good fight” for open speech. Listen to his TTS models!`}</p>
    <div align="center">
  <audio controls src="https://coqui-ai-public-data.s3.amazonaws.com/newsletter/audio/thorsten-on-berlin.wav">
    Your browser does not support the
    <code>audio</code> element.
  </audio>
    </div>
    <p>{`Breathtaking!`}</p>
    <p>{`In this newsletter we also want to highlight some of the 🔥💯amazing💯🔥 talent
we’ve recently brought on-board, a quartet of new hires that’s rounding out our
TTS and MLOps teams with their amazing skills👏👏👏👏. (And their amazing swag😎.)
Details below the fold!`}</p>
    <p>{`Finally, we wanted to circle back on the #HackThePlanet hackathon, run jointly by
Mozilla and Coqui, by paying homage to all the participants and winners!`}</p>
    <p>{`Enjoy the newsletter! 🐸`}</p>
    <h3 {...{
      "id": "community-spotlight-thorsten-mueller-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#community-spotlight-thorsten-mueller-",
        "aria-label": "community spotlight thorsten mueller  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Community Spotlight: Thorsten Mueller 🇩🇪💬`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "109.60000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAWABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAYHAwQF/8QAGAEAAgMAAAAAAAAAAAAAAAAAAwUBBAb/2gAMAwEAAhADEAAAAamtqu5FIY0kCmTl7psFGcKSf//EABsQAAMAAwEBAAAAAAAAAAAAAAMEBQECBhUU/9oACAEBAAEFAl8fUbrF0vKmUMqLecIZ7Ty9PROaQi67ZZjNajoHXnMbmn//xAAaEQEAAgMBAAAAAAAAAAAAAAABAAIDBCER/9oACAEDAQE/Aa7GErYYZOcnqDUlXk//xAAaEQACAwEBAAAAAAAAAAAAAAABAgADERQh/9oACAECAQE/AeS7whYWCnDFOrstprZySJ//xAAhEAACAgICAQUAAAAAAAAAAAABAgMSABEhIjETI0Fhof/aAAgBAQAGPwKFT1VmAs3gZSCCOBoeEKt8eNZTajtvsc9yS1eCkQ1+5WMOnOxYDQxTG0ZGTI4Vzap+8eNYEtXdtYW9Qjvn/8QAHBABAQADAAMBAAAAAAAAAAAAAREAITFRYXFB/9oACAEBAAE/IV2JldCxXBodgVWqW933jxOqEEzycEAZ0xcXkpicZt+4Mv6WHcv4GezjyZUQ8MSnr9zaArR8M//aAAwDAQACAAMAAAAQ9wDB/8QAGxEBAAICAwAAAAAAAAAAAAAAAQARIeFBYfD/2gAIAQMBAT8QcEvjWpcFY91FKYsiFCf/xAAcEQEAAgIDAQAAAAAAAAAAAAABABEhMVFhgfD/2gAIAQIBAT8QFMI/Z49jh0TZWowLdS0MvbP/xAAeEAEAAgICAwEAAAAAAAAAAAABESEAMUFRYYGhwf/aAAgBAQABPxBf0W2OwGi95TyAhSAlRmRTyY5WWogEQCRW8HFheJlClvsN4AA4nEiRyy8iPmEypCKzuZubN5P2RuJCfcnHeTGLp7JXg571gxtnXK/7n//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/efe3913df892c9e562a961459835bb50/dbdff/thorsten_mueller.jpg",
          "srcSet": ["/static/efe3913df892c9e562a961459835bb50/0988f/thorsten_mueller.jpg 250w", "/static/efe3913df892c9e562a961459835bb50/d1f95/thorsten_mueller.jpg 500w", "/static/efe3913df892c9e562a961459835bb50/dbdff/thorsten_mueller.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai"
      }}>{`Coqui🐸`}</a></p>
    <p>{`This month we wanted to shine our spotlight on Thorsten Mueller. Thorsten has been
“fighting the good fight” for open speech for some time now, creating free open
high-quality German TTS voices. Here is a prime example of his work`}</p>
    <div align="center">
  <audio controls src="https://coqui-ai-public-data.s3.amazonaws.com/newsletter/audio/thorsten-on-berlin.wav">
    Your browser does not support the
    <code>audio</code> element.
  </audio>
    </div>
    <p>{`As you can tell from the quality of TTS voices he’s created, his passion for open
speech has led him to produce models that are basically indistinguishable from
humans. Quite an achievement! 👏👏👏`}</p>
    <p>{`Not only does Thorsten create flawless TTS models and release them under an open
license for the good of open speech, he teaches others how to do the same!
He’s created a `}<a parentName="p" {...{
        "href": "https://www.youtube.com/c/ThorstenMueller/videos"
      }}>{`YouTube channel`}</a>{`
that walks you through the details of how he gets his TTS to “sing”.`}</p>
    <p>{`I don’t know how he finds the time to juggle all of this and a full-time job! Hats
off to Thorsten! We’re lucky to have him as part of our community!💥🔥💯👏👏👏`}</p>
    <h3 {...{
      "id": "coquis-new-hires-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coquis-new-hires-",
        "aria-label": "coquis new hires  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui’s New Hires 🐸🐸🐸🐸`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.2%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAQFBv/EABYBAQEBAAAAAAAAAAAAAAAAAAUCA//aAAwDAQACEAMQAAAByM0C2ohZv//EABkQAQACAwAAAAAAAAAAAAAAAAEDBAIFE//aAAgBAQABBQKsVMYyTXLIHT//xAAYEQEAAwEAAAAAAAAAAAAAAAABAhASIf/aAAgBAwEBPwGAZOV//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABsQAAICAwEAAAAAAAAAAAAAAAECACEDERJB/9oACAEBAAY/AkLo5Ng69qHrDmPtHUbml3Qn/8QAGRABAQADAQAAAAAAAAAAAAAAAREAMVEh/9oACAEBAAE/IWXkFETtvuVqFiIPeXmXlMmmjP/aAAwDAQACAAMAAAAQdA//xAAXEQEBAQEAAAAAAAAAAAAAAAABADGR/9oACAEDAQE/EEMjhO3/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EEr21v/EABoQAQADAQEBAAAAAAAAAAAAAAEAESFBMWH/2gAIAQEAAT8QKgLqQCr40VVQpSwXGnBrivBX5KSsATphP//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/7730eb2f4125d539aa6c11e1d4aa8873/dbdff/welcome.jpg",
          "srcSet": ["/static/7730eb2f4125d539aa6c11e1d4aa8873/0988f/welcome.jpg 250w", "/static/7730eb2f4125d539aa6c11e1d4aa8873/d1f95/welcome.jpg 500w", "/static/7730eb2f4125d539aa6c11e1d4aa8873/dbdff/welcome.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai"
      }}>{`Coqui🐸`}</a></p>
    <p>{`Coqui is growing by leaps and bounds (👶➙🧒➙🧑➙🦄) and with that comes new hires.`}</p>
    <p>{`And we’ve just landed quite the quartet:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Edresson"
        }}>{`Edresson`}</a>{` - A talent🧨 at TTS and freshly minted PhD from University of São Paulo.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/gorkemgoknar"
        }}>{`Görkem`}</a>{` - The MLOps wizard 🧙‍♂️ previously at Turkcell and Nokia.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/WeberJulian"
        }}>{`Julian`}</a>{` - A TTS talent🔥 previously at Sopra Banking Software and BNP.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/loganhart420"
        }}>{`Logan`}</a>{` - A TTS intern extraordinaire from NKU and a diamond💎 rounding out the TTS team.`}</li>
    </ul>
    <p>{`With these hires, we’ve doubled our size in a matter of months!✨🧨🔥🔥`}</p>
    <p>{`We’re lucky to have all of them on-board as they’re all supremely talented, not to
mention a pleasure to work with. This 🐸 is on 🔥!`}</p>
    <h3 {...{
      "id": "coqui-swag-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-swag-",
        "aria-label": "coqui swag  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui Swag 😎`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.2%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD2ElEQVQ4yy2T7U+TVxiHDyxxMcuWLVmyZFmWfd4/gCZsy7LMuU2FGDeHgKUtYhUnwkRFRxEoYHlpKVAoa/u02BeglFKBvqBQqNRCrQOV1k2c+7I/5NrB8uHKSe775Hp+9/3kiLovBL9+LWj+TqD/UWA4IbhzUtD3k2DgtMD0i8C8j2mfvfpe33hK0CPvGsoE7ccFbccE4so3gpbvBZ1lRfSeKmKwQjBSJRirFvxxVmBXyXOf8X1ssm6V/aFK+SF5v+/nfXG5FF4/KuiQqYzlhRRWKRuvLoiUGoFbI9EW45KnS11AkThlzy6xqYoYriok30ssbv0g05UXM9pwCKv2Q2zqgzjPvYdS9y5K7ds4pNhRvScVeOrewqs7gLf+HTwXDr6pOWXa8bOFIINSKrQlsni1lOz9ANtxhadLCi9TQZ4mptmITrDqayfSX87kzVLmTBoWXG0szY6yNOcgaKpkqukTPBffL0jlCoSrv4n/HofYzcTIba6QlaJE1E82GWUjsUgmucTOoyjzlgaijlZWQjaSsUnWVxaJuG4QvF1CoOMIipxk6EwRwjfQwJ8xhQezDikLk4pL4YKf9fgMc14bHqsBv6kJd1cN0yNXmb/bz8K0jaVoiJj1NKGGYqb0pXjk+JaKYkSbqoR/0wF2sw/IpaPkMgm2Hj0gEg4SngvKhBH83XXYOqrobVXT1qhG36zD2GMgbjlG+LcD+H4/xFStYPSMHLlDfRiftZtuoxmNRkv95SZ09Zc5VaHC4fWS3lzF2FhBePACT+bNbCX8bG8s8HQ7xfpUC3PdRwh0leG7+AFjmo8QBt0RjpdVUF2tRaU+T+35y1y4dIVLjdcwWYbJ558xY9Vj1H6F0lrJpBw/ON6O13KTgKWRFeUaS5azRIwnCXWcQDgNtWhqtHTdkgJDK4q1jxn3CD67GXOPnuDdIR7PD9MjV6M6/DE1pZ+i/vIzar/9HFtrFeEhHbNmHTP95wj0ahCpgJlkcJTMopOdFS+v0jPsrPrIRBXSsnZ/2kLM08PGrJE1bzv3xq4Tsl5j0d1JZmGIJ+E7PA51sxHoYN2vR0QcnbxOTbG9fJeo3yz/6m3GB24wYmzGPthC0NXF8uwg2/fHebnq4O81p0ThRcJBPmEnt2LnWWyYrfk+KTciFHMLntF2XMOtzCjdxGXizZiNfNLNbtrLP2kPr1JuXiYV/nroJv9wgvy6h3zKz/OEfAjxMbZiY2TnTWTvDSDSC1Y5qsJuysvrzUmJT76UCV4kJ8itucmtusglnDxftst7ci1rE+w89PJ8zSNT23kSGSEbHZV7trzhf5XXAQ+1Vs7SAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/6fb1ecae8cd7af89e575ad56399c27fb/da8b6/swag.png",
          "srcSet": ["/static/6fb1ecae8cd7af89e575ad56399c27fb/43fa5/swag.png 250w", "/static/6fb1ecae8cd7af89e575ad56399c27fb/c6e3d/swag.png 500w", "/static/6fb1ecae8cd7af89e575ad56399c27fb/da8b6/swag.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai"
      }}>{`Coqui🐸`}</a></p>
    <p>{`Naturally we welcomed all the new hires with Swag😎!`}</p>
    <p>{`So, if you want swag😎 or just want the opportunity to work on the cutting edge of
speech tech, then checkout our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`open positions`}</a>{` and join us in our mission
to bring speech technology to the world’s `}<em parentName="p">{`other`}</em>{` 7000 languages`}</p>
    <h3 {...{
      "id": "hacktheplanet-hackathon-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hacktheplanet-hackathon-",
        "aria-label": "hacktheplanet hackathon  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`#`}{`HackThePlanet HaCkAtHoN 😼💻`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.400000000000006%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECBAf/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAewVextDDP8A/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQMABAIQEv/aAAgBAQABBQLOyIk9QSqwsXr/xAAYEQACAwAAAAAAAAAAAAAAAAACEBEhMv/aAAgBAwEBPwEinFL/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAeEAEAAQIHAAAAAAAAAAAAAAABAgAQAxESISJxof/aAAgBAQAGPwJGHtZmFp7bLLfkl//EABwQAQACAQUAAAAAAAAAAAAAAAEAERAhMUFhgf/aAAgBAQABPyFq4g1CO52nZNSvYmljacDn/9oADAMBAAIAAwAAABAX3//EABkRAQADAQEAAAAAAAAAAAAAAAEAESFBkf/aAAgBAwEBPxAwFj3bv2GE/8QAFhEBAQEAAAAAAAAAAAAAAAAAASEQ/9oACAECAQE/EFLM/8QAGxABAQACAwEAAAAAAAAAAAAAAREAIRAxQVH/2gAIAQEAAT8QcNtSLPdGRDgESWnhG4qCfwrJb0mDoBo5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1f21a3f337636762653a3d4b7deaa48d/dbdff/HackThePlanetv2.jpg",
          "srcSet": ["/static/1f21a3f337636762653a3d4b7deaa48d/0988f/HackThePlanetv2.jpg 250w", "/static/1f21a3f337636762653a3d4b7deaa48d/d1f95/HackThePlanetv2.jpg 500w", "/static/1f21a3f337636762653a3d4b7deaa48d/dbdff/HackThePlanetv2.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai"
      }}>{`Coqui🐸`}</a></p>
    <p>{`Mozilla and Coqui teamed up to #HackThePlanet, staging a unique hackathon focused
on building real, voice-enabled products.`}</p>
    <p>{`Participants pulled down pre-trained speech-to-text and text-to-speech models and
went to work building product! (None of that pesky machine learning getting in
the way!😉)`}</p>
    <p>{`The creativity of the participants was reflected in the breadth of ideas considered:`}</p>
    <ul>
      <li parentName="ul">{`TTS for people who have lost the power of speech`}</li>
      <li parentName="ul">{`STT + Translation engine for people who share no common language`}</li>
      <li parentName="ul">{`STT plugin to transcribe meetings for video conferencing platforms`}</li>
      <li parentName="ul">{`Along with many others, too many to mention!`}</li>
    </ul>
    <p>{`However, the winner wasn’t judged on creativity alone, but on creativity `}<em parentName="p">{`and`}</em>{` execution,
and Team Tichyen executed, creating `}<a parentName="p" {...{
        "href": "https://github.com/HarikalarKutusu/3d-voice-chess"
      }}>{`3d-voice-chess`}</a>{`
a 3D voice powered chess game in the blink of an eye. All hail Team Tichyen! And all
hail all the other teams that participated! 👏👏👏`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      